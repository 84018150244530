import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';

function App() {

  const [domain, setDomain] = useState('');
  const [data, setData] = useState({});
  const [url, setUrl] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const api_key = process.env.NAMECHEAP_API_KEY;
  const url_base = "https://api.namecheap.com/xml.response?ApiUser=squarenames&ApiKey=" + api_key + "&UserName=squarenames&Command=namecheap.domains.check&ClientIp=1.2.3.4&DomainList=";

  useEffect(() => {
    const fetchData = async () => {

      setError(false);
      setLoading(true);

      try {
        const response = await fetch(url)
        if (response.ok) {
          const xml = response.text();
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xml, 'text/xml');
          const element = xmlDoc.getElementsByTagName('DomainCheckResult')[0];
          setData({
            "RegistryPremium": element.getAttribute('IsPremiumName'),
            "RenewalPrice": element.getAttribute('PremiumRenewalPrice')
          });
          console.log(data);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }

      setLoading(false);
    };

    fetchData();

  }, [url]);


  const changeHandler = (e) => {
    setDomain(e.target.value);
    console.log(e.target.value);
  }

  const clickHandler = () => {
    setUrl(url_base + domain)
  }

  const submitHandler = (e) => {
    e.preventDefault();
    setDomain('');
  }

  return (
    <Container>

      <h1>Check registry premium domains</h1>

      <Form className="mb-3" onSubmit={submitHandler}>
        <Form.Group className="mb-3" controlId="formStackDomain">
          <Form.Label>Domain name</Form.Label>
          <Form.Control type="text" placeholder="Enter your domain" value={domain} onChange={changeHandler} />
        </Form.Group>
        <Button variant="primary" type="submit" onClick={clickHandler}>Check</Button>
      </Form>
      
    </Container>

  );

}

export default App;




